var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function calculateAverageUserGameScore(userGames) {
    var totalScore = 0;
    userGames.forEach(function (game) {
        totalScore += game.playerScore;
    });
    return totalScore / userGames.length;
}
export function calculateTotalPoints(userGames) {
    var totalScore = 0;
    userGames.forEach(function (game) {
        totalScore += game.playerScore;
    });
    return totalScore;
}
export function calculateAveragePointsPerGame(rounds) {
    var totalPoints = 0;
    var totalGames = 0;
    // Iterate through each round
    rounds.forEach(function (round) {
        // Iterate through each game within the round
        round.games.forEach(function (game) {
            // Sum points for both teams
            totalPoints += (game.team1Score + game.team2Score) / 2;
            totalGames += 1;
        });
    });
    // Calculate the average points per game
    var averagePointsPerGame = totalPoints / totalGames;
    return Math.round(averagePointsPerGame * 100) / 100;
}
export function calculateAveragePointsOverall(playersScore) {
    var totalPoints = 0;
    playersScore.forEach(function (player) {
        totalPoints += player.playerScore;
    });
    return totalPoints / playersScore.length;
}
export function findPlayerWithMostPlayedPoints(rounds) {
    var playerPoints = new Map();
    // Iterate through each round
    rounds.forEach(function (round) {
        // Iterate through each game within the round
        round.games.forEach(function (game) {
            // Sum points for team 1 players
            game.team1.forEach(function (playerIndex) {
                var points = game.team1Score + game.team2Score;
                if (!playerPoints.has(playerIndex)) {
                    playerPoints.set(playerIndex, points);
                }
                else {
                    playerPoints.set(playerIndex, playerPoints.get(playerIndex) + points);
                }
            });
            // Sum points for team 2 players
            game.team2.forEach(function (playerIndex) {
                var points = game.team1Score + game.team2Score;
                if (!playerPoints.has(playerIndex)) {
                    playerPoints.set(playerIndex, points);
                }
                else {
                    playerPoints.set(playerIndex, playerPoints.get(playerIndex) + points);
                }
            });
        });
    });
    // Find the player with the most played points
    var maxPoints = -Infinity;
    var playerWithMostPoints = null;
    playerPoints.forEach(function (points, playerIndex) {
        if (points > maxPoints) {
            maxPoints = points;
            playerWithMostPoints = { playerIndex: playerIndex, totalPoints: points };
        }
    });
    return playerWithMostPoints;
}
export function findPlayerWithLeastPlayedPoints(rounds) {
    var playerPoints = new Map();
    // Iterate through each round
    rounds.forEach(function (round) {
        // Iterate through each game within the round
        round.games.forEach(function (game) {
            // Sum points for team 1 players
            game.team1.forEach(function (playerIndex) {
                var points = game.team1Score + game.team2Score;
                if (!playerPoints.has(playerIndex)) {
                    playerPoints.set(playerIndex, points);
                }
                else {
                    playerPoints.set(playerIndex, playerPoints.get(playerIndex) + points);
                }
            });
            // Sum points for team 2 players
            game.team2.forEach(function (playerIndex) {
                var points = game.team1Score + game.team2Score;
                if (!playerPoints.has(playerIndex)) {
                    playerPoints.set(playerIndex, points);
                }
                else {
                    playerPoints.set(playerIndex, playerPoints.get(playerIndex) + points);
                }
            });
        });
    });
    // Find the player with the least played points
    var minPoints = Infinity;
    var playerWithLeastPoints = null;
    playerPoints.forEach(function (points, playerIndex) {
        if (points < minPoints) {
            minPoints = points;
            playerWithLeastPoints = { playerIndex: playerIndex, totalPoints: points };
        }
    });
    return playerWithLeastPoints;
}
export function getTeamWithLowestScore(rounds) {
    var minScore = Infinity;
    var teamWithMinScore = null;
    rounds.forEach(function (round, roundIndex) {
        round.games.forEach(function (game, gameIndex) {
            // Check team 1 score
            if (game.team1Score < minScore) {
                minScore = game.team1Score;
                teamWithMinScore = {
                    roundIndex: roundIndex,
                    gameIndex: gameIndex,
                    team: game.team1,
                    score: game.team1Score,
                };
            }
            // Check team 2 score
            if (game.team2Score < minScore) {
                minScore = game.team2Score;
                teamWithMinScore = {
                    roundIndex: roundIndex,
                    gameIndex: gameIndex,
                    team: game.team2,
                    score: game.team2Score,
                };
            }
        });
    });
    return teamWithMinScore;
}
export function getGameWithHighestPointDifference(rounds) {
    var maxPointDifference = -Infinity;
    var gameWithMaxPointDifference = null;
    rounds.forEach(function (round, roundIndex) {
        round.games.forEach(function (game, gameIndex) {
            var pointDifference = Math.abs(game.team1Score - game.team2Score);
            if (pointDifference > maxPointDifference) {
                maxPointDifference = pointDifference;
                gameWithMaxPointDifference = {
                    roundIndex: roundIndex,
                    gameIndex: gameIndex,
                    pointDifference: pointDifference,
                    game: game,
                };
            }
        });
    });
    return gameWithMaxPointDifference;
}
export function preparePlayerChartData(rounds, playerList) {
    var playerScoresPerRound = [];
    var playerCount = new Set();
    // Initialize player cumulative scores
    var cumulativeScores = new Map();
    // Process each round
    rounds.forEach(function (round) {
        var scoresThisRound = new Map();
        // Process each game within the round
        round.games.forEach(function (game) {
            game.team1.forEach(function (playerIndex) {
                playerCount.add(playerIndex);
                if (!cumulativeScores.has(playerIndex)) {
                    cumulativeScores.set(playerIndex, 0);
                }
                var newScore = (cumulativeScores.get(playerIndex) || 0) + game.team1Score;
                cumulativeScores.set(playerIndex, newScore);
                scoresThisRound.set(playerIndex, newScore);
            });
            game.team2.forEach(function (playerIndex) {
                playerCount.add(playerIndex);
                if (!cumulativeScores.has(playerIndex)) {
                    cumulativeScores.set(playerIndex, 0);
                }
                var newScore = (cumulativeScores.get(playerIndex) || 0) + game.team2Score;
                cumulativeScores.set(playerIndex, newScore);
                scoresThisRound.set(playerIndex, newScore);
            });
        });
        // Collect scores for this round
        playerScoresPerRound.push(Array.from(scoresThisRound.entries())
            .sort(function (a, b) { return a[0] - b[0]; })
            .map(function (_a) {
            var _ = _a[0], score = _a[1];
            return score;
        }));
    });
    // Initialize chart data for each player
    var playerChartData = Array.from(playerCount)
        .sort(function (a, b) { return a - b; })
        .map(function () { return ({ data: [0], label: "", showMark: false }); });
    // Assign cumulative scores to chart data
    playerScoresPerRound.forEach(function (scores, roundIndex) {
        scores.forEach(function (score, playerIndex) {
            playerChartData[playerIndex].data[roundIndex + 1] = score;
            playerChartData[playerIndex].label = playerList[playerIndex];
        });
    });
    // Ensure all rounds are represented
    playerChartData.forEach(function (playerData) {
        for (var i = 0; i < rounds.length; i++) {
            if (playerData.data[i] === undefined) {
                playerData.data[i] = i > 0 ? playerData.data[i - 1] : 0;
            }
        }
    });
    return playerChartData;
}
export function calculatePlayerScores(rounds) {
    var playerStats = new Map();
    rounds.forEach(function (round) {
        round.games.forEach(function (game) {
            var team1Wins = game.team1Score > game.team2Score;
            var team2Wins = game.team2Score > game.team1Score;
            var draw = game.team2Score === game.team1Score && game.team1Score !== 0;
            game.team1.forEach(function (playerIndex) {
                if (!playerStats.has(playerIndex)) {
                    playerStats.set(playerIndex, {
                        playerScore: 0,
                        gamesWon: 0,
                        gamesLost: 0,
                        gamesDraw: 0,
                        pointsConceded: 0,
                    });
                }
                var stats = playerStats.get(playerIndex);
                stats.playerScore += game.team1Score;
                stats.pointsConceded += game.team2Score;
                if (team1Wins) {
                    stats.gamesWon += 1;
                }
                else if (team2Wins) {
                    stats.gamesLost += 1;
                }
                else if (draw) {
                    stats.gamesDraw += 1;
                }
            });
            game.team2.forEach(function (playerIndex) {
                if (!playerStats.has(playerIndex)) {
                    playerStats.set(playerIndex, {
                        playerScore: 0,
                        gamesWon: 0,
                        gamesLost: 0,
                        gamesDraw: 0,
                        pointsConceded: 0,
                    });
                }
                var stats = playerStats.get(playerIndex);
                stats.playerScore += game.team2Score;
                stats.pointsConceded += game.team1Score;
                if (team2Wins) {
                    stats.gamesWon += 1;
                }
                else if (team1Wins) {
                    stats.gamesLost += 1;
                }
                else if (draw) {
                    stats.gamesDraw += 1;
                }
            });
        });
    });
    var result = [];
    playerStats.forEach(function (stats, playerIndex) {
        result.push(__assign({ playerIndex: playerIndex }, stats));
    });
    return result.sort(function (a, b) {
        // Sort by playerScore (highest to lowest)
        if (b.playerScore !== a.playerScore) {
            return b.playerScore - a.playerScore;
        }
        // If playerScore is the same, sort by pointsConceded (lowest to highest)
        if (a.pointsConceded !== b.pointsConceded) {
            return a.pointsConceded - b.pointsConceded;
        }
        // If pointsConceded is the same, sort by gamesWon (highest to lowest)
        if (b.gamesWon !== a.gamesWon) {
            return b.gamesWon - a.gamesWon;
        }
        // If gamesWon is the same, sort by gamesLost (lowest to highest)
        return a.gamesLost - b.gamesLost;
    });
}
export function reorderAfricano(playerScore) {
    return playerScore.sort(function (a, b) {
        var aTotal = (a.gamesWon * 2) + a.gamesDraw; // wins are worth 2 draws are worth 1
        var bTotal = (b.gamesWon * 2) + b.gamesDraw;
        if (aTotal != bTotal) {
            return bTotal - aTotal;
        }
        if (a.gamesWon != b.gamesWon) {
            return b.gamesWon - a.gamesWon;
        }
        if (a.gamesDraw != b.gamesDraw) {
            return b.gamesDraw - a.gamesDraw;
        }
        if (a.playerScore != b.playerScore) {
            return b.playerScore - a.playerScore;
        }
        else {
            return b.pointsConceded - a.pointsConceded;
        }
    });
}
// Africano Logic
export function getAfricanoGroups(rounds) {
    // every alternate round is a group
    var groupA = [];
    var groupB = [];
    for (var i = 0; i < rounds.length; i += 2) {
        groupA.push(rounds[i]);
        groupB.push(rounds[i + 1]);
    }
    return [groupA, groupB];
}
export function getTotalGroupStageRounds(playerList) {
    return (((playerList.length / (2 * 2)) - 1) * 2) - 1;
}
export function isGroupStages(matchData) {
    var playerList = matchData.playerList;
    return matchData.round <= getTotalGroupStageRounds(playerList);
}
export function isSemiFinals(matchData) {
    var playerList = matchData.playerList;
    var totalGroupRounds = getTotalGroupStageRounds(playerList);
    var semiFinalRound = totalGroupRounds + 1;
    return matchData.round == semiFinalRound; // -1 becuase index
}
export function isFinals(matchData) {
    return matchData.round + 1 == matchData.rounds.length;
}
export function isPositionalMatches(matchData) {
    var playerList = matchData.playerList;
    var totalGroupRounds = getTotalGroupStageRounds(playerList);
    var positionalRounds = totalGroupRounds + 2;
    return matchData.round == positionalRounds;
}
export function roundToLabel(matchData, modifier) {
    if (modifier === void 0) { modifier = 0; }
    var totalGroupRounds = getTotalGroupStageRounds(matchData.playerList);
    var semiFinalRound = totalGroupRounds + 1;
    var positionalRounds = totalGroupRounds + 2;
    if ((matchData.round + modifier) <= totalGroupRounds) {
        return ["Group Stage", "Group Stage"];
    }
    else if ((matchData.round + modifier) == semiFinalRound) {
        return ["Semi Finals", "Semi Finals"];
    }
    else if ((matchData.round + modifier) == positionalRounds) {
        return ["Battle for 5th", "Battle for 7th"];
    }
    else {
        return ["Finals", "Battle for 3rd"];
    }
}
export function calculateKnockoutScores(rounds) {
    var playerPositions = {};
    if (rounds.length === 2) {
        // If only 2 rounds
        rounds[0].games.forEach(function (game) {
            game.team1.concat(game.team2).forEach(function (player) {
                playerPositions[player] = 1;
            });
        });
        rounds[1].games[0].team1.concat(rounds[1].games[0].team2).forEach(function (player) {
            playerPositions[player] = 5;
        });
        rounds[1].games[1].team1.concat(rounds[1].games[1].team2).forEach(function (player) {
            playerPositions[player] = 7;
        });
    }
    else if (rounds.length === 3) {
        // If 3 rounds
        var finalGame = rounds[2].games[0];
        var thirdPlaceGame = rounds[2].games[1];
        if (finalGame.team1Score !== finalGame.team2Score) {
            // Results available
            var winningTeam = finalGame.team1Score > finalGame.team2Score ? finalGame.team1 : finalGame.team2;
            var losingTeam = finalGame.team1Score > finalGame.team2Score ? finalGame.team2 : finalGame.team1;
            winningTeam.forEach(function (player) { return (playerPositions[player] = 1); });
            losingTeam.forEach(function (player) { return (playerPositions[player] = 2); });
            var thirdWinningTeam = thirdPlaceGame.team1Score > thirdPlaceGame.team2Score ? thirdPlaceGame.team1 : thirdPlaceGame.team2;
            var thirdLosingTeam = thirdPlaceGame.team1Score > thirdPlaceGame.team2Score ? thirdPlaceGame.team2 : thirdPlaceGame.team1;
            thirdWinningTeam.forEach(function (player) { return (playerPositions[player] = 3); });
            thirdLosingTeam.forEach(function (player) { return (playerPositions[player] = 4); });
        }
        else {
            // No results available, assume tied rankings
            finalGame.team1.concat(finalGame.team2).forEach(function (player) {
                playerPositions[player] = 1;
            });
            thirdPlaceGame.team1.concat(thirdPlaceGame.team2).forEach(function (player) {
                playerPositions[player] = 3;
            });
        }
        // Determine 5th to 8th places
        var fifthGame = rounds[1].games[0];
        var seventhGame = rounds[1].games[1];
        if (fifthGame.team1Score !== fifthGame.team2Score && seventhGame.team1Score !== seventhGame.team2Score) {
            var fifthWinningTeam = fifthGame.team1Score > fifthGame.team2Score ? fifthGame.team1 : fifthGame.team2;
            var fifthLosingTeam = fifthGame.team1Score > fifthGame.team2Score ? fifthGame.team2 : fifthGame.team1;
            fifthWinningTeam.forEach(function (player) { return (playerPositions[player] = 5); });
            fifthLosingTeam.forEach(function (player) { return (playerPositions[player] = 6); });
            var seventhWinningTeam = seventhGame.team1Score > seventhGame.team2Score ? seventhGame.team1 : seventhGame.team2;
            var seventhLosingTeam = seventhGame.team1Score > seventhGame.team2Score ? seventhGame.team2 : seventhGame.team1;
            seventhWinningTeam.forEach(function (player) { return (playerPositions[player] = 7); });
            seventhLosingTeam.forEach(function (player) { return (playerPositions[player] = 8); });
        }
        else {
            rounds[1].games[0].team1.concat(rounds[1].games[0].team2).forEach(function (player) {
                playerPositions[player] = 5;
            });
            rounds[1].games[1].team1.concat(rounds[1].games[1].team2).forEach(function (player) {
                playerPositions[player] = 7;
            });
        }
    }
    return playerPositions;
}
