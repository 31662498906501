import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
var ProtectedRoute = function (_a) {
    var children = _a.children;
    var _b = useAuth(), user = _b.user, loading = _b.loading;
    var location = useLocation();
    if (!user && !loading) {
        return _jsx(Navigate, { to: "/login", state: { from: location }, replace: true });
    }
    return children;
};
export default ProtectedRoute;
