var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Card, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LogoDisplay from "@/components/LogoDisplay";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { calculateKnockoutScores, calculatePlayerScores, getGameWithHighestPointDifference, getTotalGroupStageRounds, } from "shared/utils/logic";
import QRCode from "react-qr-code";
var AfricanoPostMatch = function (_a) {
    var data = _a.data;
    var theme = useTheme();
    var isPhone = !useMediaQuery(theme.breakpoints.up("sm"));
    var totalGroupRounds = getTotalGroupStageRounds(data.playerList);
    var playerScores = calculateKnockoutScores(data.rounds.slice(totalGroupRounds + 1));
    var playerTableScores = calculatePlayerScores(data.rounds)
        .filter(function (x, i) { return i % 2 === 0; })
        .sort(function (a, b) { return playerScores[b.playerIndex] - playerScores[a.playerIndex]; })
        .reverse();
    var playerPositions = Object.entries(playerScores)
        .sort(function (a, b) { return a[1] - b[1]; })
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return +key;
    });
    var playerLeastConceded = playerTableScores.reduce(function (prev, current) {
        return prev.pointsConceded < current.pointsConceded ? prev : current;
    });
    var playerMostConceded = playerTableScores.reduce(function (prev, current) {
        return prev.pointsConceded > current.pointsConceded ? prev : current;
    });
    var mvp = playerTableScores.reduce(function (prev, current) {
        return prev.playerScore - prev.pointsConceded >
            current.playerScore - current.pointsConceded
            ? prev
            : current;
    });
    var highestPointDiffStat = getGameWithHighestPointDifference(data.rounds);
    return (_jsxs(Box, __assign({ sx: {
            width: "100%",
            minHeight: "100vh",
            fontFamily: "korolev",
            backgroundColor: "#a8d25d",
            color: "#231F20",
        } }, { children: [_jsx(Box, __assign({ sx: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingX: 5,
                } }, { children: _jsx(LogoDisplay, { sessionName: data.sessionName, isLargeScreen: !isPhone, useWhiteLogo: true }) })), _jsx(Box, __assign({ sx: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                } }, { children: _jsxs(Box, __assign({ sx: { display: "flex", alignItems: "end", gap: isPhone ? 0.5 : 2 } }, { children: [_jsxs(Box, __assign({ sx: {
                                width: isPhone ? "30vw" : "10vw",
                                height: isPhone ? 150 : "20vh",
                                backgroundColor: "#231F20",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            } }, { children: [_jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontWeight: "bold",
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: "3vh",
                                        lineHeight: "1",
                                        paddingTop: "1vh",
                                    } }, { children: "2nd" })), _jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: isPhone ? "5vw" : "2.5vh",
                                        lineHeight: "2",
                                    } }, { children: data.teams[playerPositions[2] / 2].name })), _jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: "1.5vh",
                                        lineHeight: "1",
                                    } }, { children: data.playerList[playerPositions[2]].name })), _jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: "1.5vh",
                                        lineHeight: "1",
                                    } }, { children: data.playerList[playerPositions[3]].name }))] })), _jsxs(Box, __assign({ sx: {
                                width: isPhone ? "30vw" : "10vw",
                                height: isPhone ? 200 : "28vh",
                                backgroundColor: "#231F20",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            } }, { children: [_jsx(MilitaryTechIcon, { sx: {
                                        color: "white",
                                        width: isPhone ? "70px" : "100px",
                                        height: isPhone ? "70px" : "100px",
                                    } }), _jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontWeight: "bold",
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: "3vh",
                                        lineHeight: "1",
                                        paddingTop: "1vh",
                                    } }, { children: "CHAMPS" })), _jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: isPhone ? "5vw" : "2.5vh",
                                        lineHeight: "2",
                                    } }, { children: data.teams[playerPositions[0] / 2].name })), _jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: "1.5vh",
                                        lineHeight: "1",
                                    } }, { children: data.playerList[playerPositions[0]].name })), _jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: "1.5vh",
                                        lineHeight: "1",
                                    } }, { children: data.playerList[playerPositions[1]].name }))] })), _jsxs(Box, __assign({ sx: {
                                width: isPhone ? "30vw" : "10vw",
                                height: isPhone ? 100 : "13vh",
                                backgroundColor: "#231F20",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            } }, { children: [_jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontWeight: "bold",
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: "3vh",
                                        lineHeight: "1",
                                        paddingTop: "1vh",
                                    } }, { children: "3rd" })), _jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: isPhone ? "5vw" : "2.5vh",
                                        lineHeight: "1.5",
                                    } }, { children: data.teams[playerPositions[4] / 2].name })), _jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: "1.5vh",
                                        lineHeight: "1",
                                    } }, { children: data.playerList[playerPositions[4]].name })), _jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                        fontFamily: "korolev",
                                        color: "#fff",
                                        fontSize: "1.5vh",
                                        lineHeight: "1",
                                    } }, { children: data.playerList[playerPositions[5]].name }))] }))] })) })), _jsx(Box, __assign({ sx: {
                    height: "100%",
                    my: 1,
                    backgroundColor: "rgba",
                    position: "absolute",
                    top: "15vh",
                    right: "0",
                    display: isPhone ? "none" : "block",
                } }, { children: _jsxs(Grid, __assign({ item: true, xs: 12, md: 12, container: true }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 12, padding: 1 }, { children: _jsx(Box, __assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(QRCode, { bgColor: "#a8d25d", value: window.location.href, size: window.innerWidth / 10 }) })) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", component: "h2", align: "center", sx: { fontFamily: "korolev", fontSize: "1.2vw" } }, { children: "SCAN QR FOR THIS PAGE" })) }))] })) })), _jsxs(Grid, __assign({ container: true, sx: { mt: 2 } }, { children: [_jsx(Grid, __assign({ item: true, sx: { display: isPhone ? "none" : "flex" }, xs: 0, md: 4 }, { children: _jsx(Box, __assign({ sx: {
                                // This height is the available space for the table.
                                // Adjust 400px to match the height taken by your other sections.
                                height: "48vh",
                                // overflowY: "hidden",
                                paddingX: 2,
                                borderRadius: "8px",
                            } }, { children: _jsx(TeamResultTable, { playerScores: playerTableScores, teamData: data.teams, isPhone: isPhone }) })) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 4 }, { children: _jsx(Box, __assign({ sx: {
                                display: "flex",
                                flexDirection: "column",
                                gap: isPhone ? 1 : 2,
                                paddingTop: isPhone ? "2vh" : "0",
                            } }, { children: playerPositions
                                .slice(7, 16)
                                .filter(function (x, i) { return i % 2 === 0; })
                                .map(function (player, index) { return (_jsx(TeamPositionCard, { position: index + 4, teamName: data.teams[(player - 1) / 2].name, player1: data.playerList[player].name, player2: data.playerList[player - 1].name, isPhone: isPhone }, index)); }) })) })), _jsx(Grid, __assign({ item: true, sx: { display: isPhone ? "flex" : "none" }, xs: 12, md: 0 }, { children: _jsx(Box, __assign({ sx: {
                                // This height is the available space for the table.
                                // Adjust 400px to match the height taken by your other sections.
                                height: "48vh",
                                // overflowY: "hidden",
                                paddingX: 2,
                                borderRadius: "8px",
                                paddingTop: "2vh",
                            } }, { children: _jsx(TeamResultTable, { playerScores: playerTableScores, teamData: data.teams, isPhone: isPhone }) })) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 4 }, { children: _jsx(Box, __assign({ sx: {
                                width: isPhone ? "96vw" : "102%",
                                marginLeft: isPhone ? "2vw" : "-2vw",
                                paddingTop: isPhone ? "2vh" : "0",
                                paddingBottom: isPhone ? "2vh" : "0",
                            } }, { children: _jsxs(Grid, __assign({ container: true, spacing: isPhone ? 1 : 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Card, __assign({ sx: {
                                                backgroundColor: "#231F20",
                                                color: "#fff",
                                                fontFamily: "korolev",
                                                display: "flex",
                                                justifyContent: "center",
                                                borderRadius: "16px",
                                            } }, { children: _jsxs(CardContent, __assign({ sx: {
                                                    backgroundColor: "#231F20",
                                                    color: "#fff",
                                                    fontFamily: "korolev",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                } }, { children: [_jsx(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "4vw" : "3vh",
                                                        } }, { children: "Biggest Pak" })), _jsxs(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "8vw" : "8vh",
                                                            color: "#a8d25d",
                                                            lineHeight: "1",
                                                        } }, { children: [highestPointDiffStat.game.team1Score, " -", " ", highestPointDiffStat.game.team2Score] })), _jsxs(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "4vw" : "2vh",
                                                        } }, { children: [data.teams[highestPointDiffStat.game.team1[0] / 2]
                                                                .name, " ", "vs", " ", data.teams[highestPointDiffStat.game.team2[0] / 2]
                                                                .name] }))] })) })) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Card, __assign({ sx: {
                                                backgroundColor: "#231F20",
                                                color: "#fff",
                                                fontFamily: "korolev",
                                                borderRadius: "16px",
                                            } }, { children: _jsxs(CardContent, __assign({ sx: {
                                                    backgroundColor: "#231F20",
                                                    color: "#fff",
                                                    fontFamily: "korolev",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                } }, { children: [_jsx(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "4vw" : "3.2vh",
                                                        } }, { children: "MVP" })), _jsx(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "8vw" : "8vh",
                                                            color: "#a8d25d",
                                                            lineHeight: "1",
                                                        } }, { children: data.teams[mvp.playerIndex / 2].name })), _jsx(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "4vw" : "1.55vh",
                                                        } }, { children: "Highest Points Scored - Points Conceded" }))] })) })) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Card, __assign({ sx: {
                                                backgroundColor: "#231F20",
                                                color: "#fff",
                                                fontFamily: "korolev",
                                                borderRadius: "16px",
                                            } }, { children: _jsxs(CardContent, __assign({ sx: {
                                                    backgroundColor: "#231F20",
                                                    color: "#fff",
                                                    fontFamily: "korolev",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                } }, { children: [_jsx(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "4vw" : "3vh",
                                                        } }, { children: "Iron Clad Defence" })), _jsx(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "8vw" : "5vh",
                                                            color: "#a8d25d",
                                                            lineHeight: "1",
                                                        } }, { children: data.teams[playerLeastConceded.playerIndex / 2].name })), _jsx(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "4vw" : "2vh",
                                                        } }, { children: "Least Conceded Points" }))] })) })) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Card, __assign({ sx: {
                                                backgroundColor: "#231F20",
                                                color: "#fff",
                                                fontFamily: "korolev",
                                                borderRadius: "16px",
                                            } }, { children: _jsxs(CardContent, __assign({ sx: {
                                                    backgroundColor: "#231F20",
                                                    color: "#fff",
                                                    fontFamily: "korolev",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                } }, { children: [_jsx(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "4vw" : "3vh",
                                                        } }, { children: "Charity Workers" })), _jsx(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "8vw" : "5vh",
                                                            color: "#a8d25d",
                                                            lineHeight: "1",
                                                        } }, { children: data.teams[playerMostConceded.playerIndex / 2].name })), _jsx(Typography, __assign({ sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontSize: isPhone ? "4vw" : "2vh",
                                                        } }, { children: "Most Conceded Points" }))] })) })) }))] })) })) }))] }))] })));
};
var TeamResultTable = function (_a) {
    var playerScores = _a.playerScores, teamData = _a.teamData, isPhone = _a.isPhone;
    return (_jsx(TableContainer, __assign({ component: Paper, sx: {
            borderRadius: "8px",
            width: isPhone ? "100%" : "34vw",
            height: "100%", // fill parent's height
            // overflowX: "auto", // enable scrolling if content exceeds the container
        } }, { children: _jsxs(Table, __assign({ sx: { width: "100%" }, "aria-label": "simple table" }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, __assign({ sx: { height: "calc(48vh/9.1)" } }, { children: [_jsx(TableCell, __assign({ sx: {
                                    backgroundColor: "#231F20",
                                    color: "#a8d25d",
                                    border: "1px solid #231F20",
                                    fontFamily: "korolev",
                                    borderLeft: "2px solid #231F20",
                                    fontWeight: "bold",
                                    fontSize: isPhone ? "3vw" : "1.5vh",
                                    lineHeight: "0.4",
                                } }, { children: "Team" })), _jsx(TableCell, __assign({ sx: {
                                    backgroundColor: "#231F20",
                                    color: "#fff",
                                    border: "1px solid #231F20",
                                    fontFamily: "korolev",
                                    fontWeight: "bold",
                                    fontSize: isPhone ? "3vw" : "1.5vh",
                                    lineHeight: "0.4",
                                } }, { children: "Points" })), _jsx(TableCell, __assign({ sx: {
                                    backgroundColor: "#231F20",
                                    color: "#fff",
                                    border: "1px solid #231F20",
                                    fontFamily: "korolev",
                                    fontWeight: "bold",
                                    fontSize: isPhone ? "3vw" : "1.5vh",
                                    lineHeight: "0.4",
                                } }, { children: "Average" })), _jsx(TableCell, __assign({ sx: {
                                    backgroundColor: "#231F20",
                                    color: "#fff",
                                    border: "1px solid #231F20",
                                    fontFamily: "korolev",
                                    fontWeight: "bold",
                                    fontSize: isPhone ? "3vw" : "1.5vh",
                                    lineHeight: "0.4",
                                } }, { children: "Conceded" })), _jsx(TableCell, __assign({ sx: {
                                    backgroundColor: "#231F20",
                                    color: "#fff",
                                    border: "1px solid #231F20",
                                    fontFamily: "korolev",
                                    fontWeight: "bold",
                                    fontSize: isPhone ? "3vw" : "1.5vh",
                                    lineHeight: "0.4",
                                } }, { children: "W-L-D" }))] })) }), _jsx(TableBody, { children: playerScores.map(function (player, index) { return (_jsxs(TableRow, __assign({ sx: { height: "calc(48vh/9)" } }, { children: [_jsx(TableCell, __assign({ sx: {
                                    backgroundColor: "#231F20",
                                    color: "#fff",
                                    borderBottom: "2px solid #a8d25d",
                                    borderTop: "2px solid #a8d25d",
                                    borderRight: "2px solid #a8d25d",
                                    borderLeft: "2px solid #231F20",
                                    fontFamily: "korolev",
                                    fontSize: isPhone ? "3vw" : "1.5vh",
                                    lineHeight: "0.4",
                                } }, { children: teamData[(player.playerIndex === 0 ? 0 : player.playerIndex) / 2].name })), _jsx(TableCell, __assign({ sx: {
                                    border: "2px solid #a8d25d",
                                    fontFamily: "korolev",
                                    fontSize: isPhone ? "3vw" : "1.5vh",
                                    lineHeight: "0.4",
                                } }, { children: player.playerScore })), _jsx(TableCell, __assign({ sx: {
                                    border: "2px solid #a8d25d",
                                    fontFamily: "korolev",
                                    fontSize: isPhone ? "3vw" : "1.5vh",
                                    lineHeight: "0.4",
                                } }, { children: Math.round((player.playerScore /
                                    (player.gamesWon + player.gamesLost + player.gamesDraw)) *
                                    100) / 100 })), _jsx(TableCell, __assign({ sx: {
                                    border: "2px solid #a8d25d",
                                    fontFamily: "korolev",
                                    fontSize: isPhone ? "3vw" : "1.5vh",
                                    lineHeight: "0.4",
                                } }, { children: Math.round(player.pointsConceded * 100) / 100 })), _jsxs(TableCell, __assign({ sx: {
                                    border: "2px solid #a8d25d",
                                    fontFamily: "korolev",
                                    fontSize: isPhone ? "3vw" : "1.5vh",
                                    lineHeight: "0.4",
                                } }, { children: [player.gamesWon, "-", player.gamesLost, "-", player.gamesDraw] }))] }), index)); }) })] })) })));
};
var TeamPositionCard = function (_a) {
    var position = _a.position, teamName = _a.teamName, player1 = _a.player1, player2 = _a.player2, isPhone = _a.isPhone;
    return (_jsx(Card, __assign({ sx: {
            borderRadius: "8px",
            width: "".concat(isPhone ? "95%" : "80%"),
            height: "".concat(isPhone ? "10vh" : "8vh"),
            mx: "auto", // centers the card horizontally
        } }, { children: _jsxs(Grid, __assign({ container: true }, { children: [_jsx(Grid, __assign({ item: true, xs: 1, sx: {
                        backgroundColor: "#231F20",
                        height: "".concat(isPhone ? "10vh" : "8vh"),
                        color: "#fff",
                        fontFamily: "korolev",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingLeft: isPhone ? "0.5vh" : "0.5vw",
                    } }, { children: _jsx(Typography, __assign({ sx: {
                            fontWeight: "bold",
                            fontFamily: "korolev",
                            fontSize: "6vh",
                            lineHeight: "0",
                            paddingTop: isPhone ? "0.5vh" : "0.4vw",
                        } }, { children: position })) })), _jsx(Grid, __assign({ item: true, xs: 6, sx: {
                        backgroundColor: "#231F20",
                        color: "#fff",
                        paddingX: isPhone ? 2 : 4,
                        display: "flex",
                        alignItems: "center",
                    } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                            fontWeight: "bold",
                            fontFamily: "korolev",
                            fontSize: "3vh",
                            lineHeight: "0",
                        } }, { children: teamName })) })), _jsx(Grid, __assign({ item: true, xs: 5, sx: {
                        backgroundColor: "lightgrey",
                        color: "#231F20",
                        paddingX: 2,
                        paddingY: 0.5,
                        fontFamily: "korolev",
                    } }, { children: [player1, player2].map(function (player, index) { return (_jsx(Typography, __assign({ sx: {
                            fontSize: "2.5vh",
                            fontWeight: "bold",
                            fontFamily: "korolev",
                            lineHeight: 1.5,
                            mx: "auto",
                        } }, { children: player }), index)); }) }))] })) }), position));
};
export default AfricanoPostMatch;
