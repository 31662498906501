var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { basicColors } from "@/utils/colors";
import { Box, Typography } from "@mui/material";
export var LeaderboardListItem = function (_a) {
    var player = _a.player, data = _a.data;
    return (_jsxs(Box, __assign({ sx: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.55vh 0",
            borderBottom: "0.3vh solid rgb(255, 255, 255)",
            position: "relative",
        } }, { children: [_jsx(Box, { sx: {
                    width: "4%",
                    height: "7vh",
                    backgroundColor: basicColors[(player.playerIndex - 1) / 2],
                    marginRight: "1vw",
                } }), _jsxs(Box, __assign({ sx: { flex: 1, textAlign: "left" } }, { children: [_jsx(Typography, __assign({ variant: "body1", sx: { fontSize: "1.5vw", fontWeight: "bold", fontFamily: "korolev" } }, { children: data.teams[(player.playerIndex - 1) / 2].name })), _jsx(Typography, __assign({ variant: "body1", sx: {
                            fontSize: "1.1vw",
                            fontFamily: "korolev",
                            color: "rgba(0, 0, 0, 1)",
                            lineHeight: "0.3",
                        } }, { children: data.playerList[player.playerIndex - 1].name })), _jsx(Typography, __assign({ variant: "body1", sx: {
                            fontSize: "1.1vw",
                            fontFamily: "korolev",
                            color: "rgba(0, 0, 0, 1)",
                        } }, { children: data.playerList[player.playerIndex].name }))] })), _jsx(Typography, __assign({ variant: "body1", sx: {
                    fontWeight: "bold",
                    fontSize: "2.8vw",
                    fontFamily: "korolev",
                } }, { children: player.gamesWon * 2 + player.gamesDraw }))] })));
};
