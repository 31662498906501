import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Loading from "@/components/Loading";
import { listenToMatch } from "@/dao/match";
import { MatchType } from "shared/models/matchModels";
import MatchSingle from "./single/MatchSingle";
import MatchTeam from "./team/MatchTeam";
import MatchAfricano from "./africano/MatchAfricano";
var Match = function () {
    var matchId = useParams().matchId;
    var _a = useState(null), matchData = _a[0], setMatchData = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        if (matchId) {
            var unsubscribe_1 = listenToMatch(matchId, function (data) {
                setMatchData(data);
                setLoading(false);
            });
            return function () {
                unsubscribe_1();
            };
        }
    }, [matchId]);
    if (!matchId)
        return _jsx(Navigate, { to: "/display", replace: true });
    if (loading)
        return _jsx(Loading, {});
    if (matchData) {
        matchData.type = matchData.type || MatchType.SINGLE;
        switch (matchData.type) {
            case MatchType.SINGLE:
                return _jsx(MatchSingle, { matchData: matchData });
            case MatchType.TEAMED:
                return _jsx(MatchTeam, { matchData: matchData });
            case MatchType.AFRICANO:
                return _jsx(MatchAfricano, { matchData: matchData });
            default:
                return _jsx(Navigate, { to: "/display", replace: true });
        }
    }
    else {
        return _jsx(Navigate, { to: "/display", replace: true });
    }
};
export default Match;
