var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import Logo from "@/components/Logo";
import { MatchFormat, MatchState, } from "shared/models/matchModels";
import { calculateKnockoutScores, calculatePlayerScores, getAfricanoGroups, getTotalGroupStageRounds, isGroupStages, reorderAfricano, roundToLabel, } from "shared/utils/logic";
import ChevronSvg from "../../../chevrons.svg";
import useCountdown from "@/hooks/useCountdown";
import Timer from "@/components/Timer";
import { LeaderboardListItem } from "@/components/LeaderboardListItem";
import { LeaderboardPositionItem } from "@/components/LeaderboardPositionItem";
var AfricanoInfo = function (_a) {
    var data = _a.data;
    var africanoGroups = getAfricanoGroups(data.rounds.slice(0, getTotalGroupStageRounds(data.playerList) + 1));
    var playerScoreGroupA = reorderAfricano(calculatePlayerScores(africanoGroups[0]));
    var playerScoreGroupB = reorderAfricano(calculatePlayerScores(africanoGroups[1]));
    var groupStage = isGroupStages(data);
    var playerScores = {};
    if (!groupStage) {
        var totalGroupRounds = getTotalGroupStageRounds(data.playerList);
        playerScores = calculateKnockoutScores(data.rounds.slice(totalGroupRounds + 1));
    }
    var previosRound = data.round > 0 ? data.rounds[data.round - 1] : undefined;
    var currentRound = data.rounds[data.round];
    var nextRound = data.round < data.rounds.length ? data.rounds[data.round + 1] : undefined;
    var _b = useCountdown(data.limit, data.format === MatchFormat.TIMED && data.state === MatchState.IN_PROGRESS), initialCountdown = _b.initialCountdown, showTimer = _b.showTimer, countdown = _b.countdown, formatTime = _b.formatTime;
    var previousRoundLabels = roundToLabel(data, -1);
    var currentRoundLabels = roundToLabel(data);
    var nextRoundLabels = roundToLabel(data, +1);
    return (_jsxs(Box, __assign({ sx: {
            height: "100vh",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "background.default",
            color: "text.primary",
        } }, { children: [_jsxs(Box, __assign({ sx: {
                    flex: 1,
                    backgroundColor: "#8BC53F",
                    color: "common.white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "2vh",
                } }, { children: [_jsx(Typography, __assign({ variant: "h5", sx: {
                            fontWeight: "bold",
                            textAlign: "center",
                            fontFamily: "bc-alphapipe",
                            fontSize: "2.6vw",
                        } }, { children: "Leaderboard" })), _jsx(Box, __assign({ sx: { width: "100%", marginTop: "1vh" } }, { children: groupStage ? (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "h5", sx: {
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        fontFamily: "korolev",
                                        fontSize: "1.8vw",
                                    } }, { children: "GROUP A" })), playerScoreGroupA.map(function (player, index) {
                                    if (index % 2 === 0)
                                        return null; // Skip even indices, handle them in the odd index case
                                    return (_jsx(LeaderboardListItem, { player: player, data: data }, index));
                                }), _jsx(Typography, __assign({ variant: "h5", sx: {
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        fontFamily: "korolev",
                                        fontSize: "1.8vw",
                                        paddingY: "2vh",
                                    } }, { children: "GROUP B" })), playerScoreGroupB.map(function (player, index) {
                                    if (index % 2 === 0)
                                        return null; // Skip even indices, handle them in the odd index case
                                    return (_jsx(LeaderboardListItem, { player: player, data: data }, index));
                                })] })) : (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "h5", sx: {
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        fontFamily: "korolev",
                                        fontSize: "1.8vw",
                                    } }, { children: "LEADERBOARD" })), Object.entries(playerScores)
                                    .sort(function (a, b) { return a[1] - b[1]; })
                                    .map(function (_a, index) {
                                    var player = _a[0], position = _a[1];
                                    if (index % 2 === 0)
                                        return null; // Skip even indices, handle them in the odd index case
                                    return (_jsx(LeaderboardPositionItem, { playerIndex: +player, data: data, position: position }, index));
                                })] })) }))] })), _jsxs(Box, __assign({ sx: {
                    flex: 4,
                    padding: "3vh",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#f0f4ea",
                    position: "relative",
                } }, { children: [showTimer ? (_jsx(Box, __assign({ sx: {
                            position: "absolute",
                            top: "2vh",
                            right: "3vh",
                            backgroundColor: "#8BC53F",
                            color: "white",
                            padding: "0vh 4vh",
                            borderRadius: "15px",
                        } }, { children: _jsx(Timer, { initialCountdown: initialCountdown, countdown: countdown, formatTime: formatTime, roundNumber: data.round + 1, small: true }) }))) : null, _jsx(Box, __assign({ sx: { textAlign: "left", marginTop: "4vh", position: "absolute" } }, { children: _jsx(Logo, { width: "12vw", height: "auto" }) })), _jsx(Box, __assign({ sx: { textAlign: "center", marginTop: "4vh", marginBottom: "6vh" } }, { children: _jsx(Typography, __assign({ variant: "h4", sx: { fontFamily: "korolev", fontSize: "7.5vh" } }, { children: data.sessionName })) })), _jsxs(Grid, __assign({ container: true, spacing: 0, sx: { borderTop: "0.4vh solid #8BC53F", paddingTop: "2vh" } }, { children: [_jsxs(Grid, __assign({ item: true, xs: 4 }, { children: [_jsx(Typography, __assign({ variant: "h6", sx: {
                                            textAlign: "center",
                                            marginBottom: "1vh",
                                            fontFamily: "korolev",
                                            fontSize: "1.5vw",
                                        } }, { children: "PREVIOUS ROUND" })), previosRound &&
                                        previosRound.games.map(function (game, index) { return (_jsxs(Paper, __assign({ elevation: 0, sx: {
                                                paddingX: "0.5vh",
                                                marginBottom: "2vh",
                                                position: "relative",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                paddingRight: "5vw",
                                                borderRadius: "0vw",
                                                backgroundColor: "lightgrey",
                                            } }, { children: [_jsxs(Box, __assign({ sx: { flex: 1, textAlign: "center" } }, { children: [_jsx(Box, __assign({ sx: {
                                                                position: "relative",
                                                                backgroundColor: "#ffffff",
                                                                color: "#000000",
                                                                transform: "scale(1.04)",
                                                            } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                                                    fontWeight: "bold",
                                                                    fontFamily: "korolev",
                                                                    fontStyle: "italic",
                                                                    fontSize: "0.9vw",
                                                                } }, { children: previousRoundLabels[index] })) })), _jsxs(Typography, __assign({ variant: "h6", sx: {
                                                                color: "grey",
                                                                textAlign: "center",
                                                                fontFamily: "korolev",
                                                                fontSize: "1.8vw",
                                                            } }, { children: ["COURT ", index + 1] })), _jsxs(Box, __assign({ sx: {
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                paddingX: "0.4vh",
                                                            } }, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                                                                        fontFamily: "korolev",
                                                                        fontSize: "1vw",
                                                                        textAlign: "left",
                                                                        position: "absolute",
                                                                        bottom: "25%",
                                                                        left: "3%",
                                                                    } }, { children: data.teams[game.team1[0] / 2].name })), _jsxs(Typography, __assign({ variant: "h6", sx: {
                                                                        fontFamily: "korolev",
                                                                        fontSize: "2vw",
                                                                        textAlign: "center",
                                                                        width: "100%",
                                                                    } }, { children: [game.team1Score, ":", game.team2Score] })), _jsx(Typography, __assign({ variant: "body2", sx: {
                                                                        fontFamily: "korolev",
                                                                        fontSize: "1vw",
                                                                        textAlign: "right",
                                                                        position: "absolute",
                                                                        bottom: "25%",
                                                                        right: "5vw",
                                                                    } }, { children: data.teams[game.team2[0] / 2].name.substring(0, 10) }))] }))] })), _jsx(Box, __assign({ sx: {
                                                        position: "absolute",
                                                        backgroundColor: "#ffffff",
                                                        color: "#ffffff",
                                                        right: "0.3vw",
                                                        top: "0vh",
                                                        transform: "scale(1.04)",
                                                        marginLeft: "-100px",
                                                    } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontStyle: "italic",
                                                            fontSize: "0.9vw",
                                                        } }, { children: "FILLERRRRRRRR" })) })), _jsx("img", { src: ChevronSvg, alt: "Chevron", style: {
                                                        position: "absolute",
                                                        height: "50%",
                                                        right: "1vw",
                                                        top: "25%",
                                                    } })] }), index)); })] })), _jsxs(Grid, __assign({ item: true, xs: 4, sx: { zIndex: 2 } }, { children: [_jsx(Typography, __assign({ variant: "h6", sx: {
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            marginBottom: "1vh",
                                            fontFamily: "korolev",
                                            fontSize: "1.5vw",
                                        } }, { children: "CURRENT ROUND" })), currentRound.games.map(function (game, index) { return (_jsx(Paper, __assign({ elevation: 0, sx: {
                                            paddingX: "1vh",
                                            marginBottom: "2vh",
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            backgroundColor: "#8BC53F",
                                            color: "white",
                                            borderRadius: "0.8vw",
                                            transform: "scale(1.04)",
                                            zIndex: 2,
                                        } }, { children: _jsxs(Box, __assign({ sx: { flex: 1, textAlign: "center" } }, { children: [_jsx(Box, __assign({ sx: {
                                                        position: "relative",
                                                        backgroundColor: "#000000",
                                                        color: "white",
                                                        // padding: "0vh 0vw",
                                                        borderTopRightRadius: "0.8vw",
                                                        borderTopLeftRadius: "0.8vw",
                                                        transform: "scale(1.04)",
                                                        // paddingX: "1vh",
                                                    } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontStyle: "italic",
                                                            fontSize: "0.9vw",
                                                        } }, { children: currentRoundLabels[index] })) })), _jsxs(Typography, __assign({ variant: "h6", sx: {
                                                        fontWeight: "bold",
                                                        textAlign: "center",
                                                        fontFamily: "korolev",
                                                        fontSize: "1.8vw",
                                                    } }, { children: ["COURT ", index + 1] })), _jsxs(Box, __assign({ sx: {
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        textAlign: "center",
                                                        paddingX: "0.4vh",
                                                        width: "100%",
                                                    } }, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                                                                fontFamily: "korolev",
                                                                fontSize: "1vw",
                                                                textAlign: "left",
                                                                fontWeight: "bold",
                                                                position: "absolute",
                                                                bottom: "25%",
                                                                left: "3%",
                                                            } }, { children: data.teams[game.team1[0] / 2].name })), _jsx(Typography, __assign({ variant: "h6", sx: {
                                                                fontWeight: "bold",
                                                                fontFamily: "korolev",
                                                                fontSize: "2vw",
                                                                textAlign: "center",
                                                                width: "100%",
                                                            } }, { children: "VS" })), _jsx(Typography, __assign({ variant: "body2", sx: {
                                                                fontFamily: "korolev",
                                                                fontSize: "1vw",
                                                                textAlign: "right",
                                                                fontWeight: "bold",
                                                                position: "absolute",
                                                                bottom: "25%",
                                                                right: "3%",
                                                            } }, { children: data.teams[game.team2[0] / 2].name.substring(0, 10) }))] }))] })) }), index)); })] })), _jsxs(Grid, __assign({ item: true, xs: 4 }, { children: [_jsx(Typography, __assign({ variant: "h6", sx: {
                                            textAlign: "center",
                                            marginBottom: "1vh",
                                            fontFamily: "korolev",
                                            fontSize: "1.5vw",
                                        } }, { children: "NEXT ROUND" })), nextRound &&
                                        nextRound.games.map(function (game, index) { return (_jsxs(Paper, __assign({ elevation: 0, sx: {
                                                paddingX: "0.5vh",
                                                marginBottom: "2vh",
                                                position: "relative",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                paddingLeft: "5vw",
                                                borderRadius: "0vw",
                                            } }, { children: [_jsx(Box, __assign({ sx: {
                                                        position: "absolute",
                                                        backgroundColor: "grey",
                                                        color: "grey",
                                                        left: "0.3vw",
                                                        top: "0vh",
                                                        transform: "scale(1.04)",
                                                    } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                                            fontWeight: "bold",
                                                            fontFamily: "korolev",
                                                            fontStyle: "italic",
                                                            fontSize: "0.9vw",
                                                        } }, { children: "FILLERRRRRRRR" })) })), _jsx("img", { src: ChevronSvg, alt: "Chevron", style: {
                                                        position: "absolute",
                                                        height: "50%",
                                                        left: "1vw",
                                                        top: "25%",
                                                    } }), _jsxs(Box, __assign({ sx: { flex: 1, textAlign: "center" } }, { children: [_jsx(Box, __assign({ sx: {
                                                                position: "relative",
                                                                backgroundColor: "grey",
                                                                color: "#ffffff",
                                                                transform: "scale(1.04)",
                                                            } }, { children: _jsx(Typography, __assign({ variant: "h6", sx: {
                                                                    fontWeight: "bold",
                                                                    fontFamily: "korolev",
                                                                    fontStyle: "italic",
                                                                    fontSize: "0.9vw",
                                                                } }, { children: nextRoundLabels[index] })) })), _jsxs(Typography, __assign({ variant: "h6", sx: {
                                                                color: "grey",
                                                                textAlign: "center",
                                                                fontFamily: "korolev",
                                                                fontSize: "1.8vw",
                                                            } }, { children: ["COURT ", index + 1] })), _jsxs(Box, __assign({ sx: {
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                paddingX: "0.4vh",
                                                            } }, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                                                                        fontFamily: "korolev",
                                                                        fontSize: "1vw",
                                                                        textAlign: "left",
                                                                        position: "absolute",
                                                                        bottom: "25%",
                                                                        left: "5vw",
                                                                    } }, { children: data.teams[game.team1[0] / 2].name })), _jsx(Typography, __assign({ variant: "h6", sx: {
                                                                        fontWeight: "bold",
                                                                        fontFamily: "korolev",
                                                                        fontSize: "2vw",
                                                                        textAlign: "center",
                                                                        width: "100%",
                                                                    } }, { children: "VS" })), _jsx(Typography, __assign({ variant: "body2", sx: {
                                                                        fontFamily: "korolev",
                                                                        fontSize: "1vw",
                                                                        textAlign: "right",
                                                                        position: "absolute",
                                                                        bottom: "25%",
                                                                        right: "3%",
                                                                    } }, { children: data.teams[game.team2[0] / 2].name.substring(0, 10) }))] }))] }))] }), index)); })] }))] }))] }))] })));
};
export default AfricanoInfo;
