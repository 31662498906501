export var MatchFormat;
(function (MatchFormat) {
    MatchFormat["POINTS"] = "POINTS";
    MatchFormat["TIMED"] = "TIMED";
})(MatchFormat || (MatchFormat = {}));
export var MatchState;
(function (MatchState) {
    MatchState["NEW"] = "NEW";
    MatchState["READY"] = "READY";
    MatchState["IN_PROGRESS"] = "IN_PROGRESS";
    MatchState["FINISHED"] = "FINISHED";
})(MatchState || (MatchState = {}));
export var MatchType;
(function (MatchType) {
    MatchType["SINGLE"] = "SINGLE";
    MatchType["TEAMED"] = "TEAMED";
    MatchType["AFRICANO"] = "AFRICANO";
})(MatchType || (MatchType = {}));
