var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Typography } from "@mui/material";
export var LeaderboardPositionItem = function (_a) {
    var playerIndex = _a.playerIndex, position = _a.position, data = _a.data;
    return (_jsxs(Box, __assign({ sx: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.55vh 0",
            borderBottom: "0.3vh solid rgb(255, 255, 255)",
            position: "relative",
        } }, { children: [_jsx(Typography, __assign({ variant: "body1", sx: {
                    fontWeight: "bold",
                    fontSize: "4vw",
                    fontFamily: "korolev",
                    width: "3vw",
                    textAlign: "center",
                    lineHeight: "0.1",
                    color: "#000000",
                } }, { children: position })), _jsxs(Box, __assign({ sx: { flex: 1, textAlign: "left", marginLeft: "1vw" } }, { children: [_jsx(Typography, __assign({ variant: "body1", sx: { fontSize: "1.7vw", fontWeight: "bold", fontFamily: "korolev" } }, { children: data.teams[(playerIndex - 1) / 2].name })), _jsx(Typography, __assign({ variant: "body1", sx: {
                            fontSize: "1.1vw",
                            fontFamily: "korolev",
                            color: "rgba(0, 0, 0, 1)",
                            lineHeight: "0.3",
                        } }, { children: data.playerList[playerIndex - 1].name })), _jsx(Typography, __assign({ variant: "body1", sx: {
                            fontSize: "1.1vw",
                            fontFamily: "korolev",
                            color: "rgba(0, 0, 0, 1)",
                        } }, { children: data.playerList[playerIndex].name }))] }))] })));
};
