var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MainGrid from "@/components/admin/MainGrid";
import SideMenu from "@/components/admin/SideMenu";
import { useAuth } from "@/context/AuthContext";
import { fetchUserClubs } from "@/dao/club";
// import { auth } from "@/utils/firestore";
import { alpha, Box, Stack } from "@mui/material";
import React from "react";
var Admin = function () {
    //   const handleLogout = () => {
    //     auth.signOut();
    //   };
    var _a = useAuth(), user = _a.user, loading = _a.loading;
    var _b = React.useState([]), clubs = _b[0], setClubs = _b[1];
    var _c = React.useState(), club = _c[0], setClub = _c[1];
    var _d = React.useState(true), clubsLoading = _d[0], setClubsLoading = _d[1];
    React.useEffect(function () {
        if (user) {
            fetchUserClubs(user.uid).then(function (data) {
                setClubs(data);
                setClub(data[0]);
                setClubsLoading(false);
            });
        }
    }, [user]);
    if (loading || clubsLoading)
        return _jsx("div", { children: "Loading..." });
    if (user === null)
        return _jsx("div", { children: "Not logged in" });
    return (_jsxs(Box, __assign({ sx: { display: "flex" } }, { children: [_jsx(SideMenu, { user: user, clubs: clubs }), _jsx(Box, __assign({ component: "main", sx: function (theme) { return ({
                    flexGrow: 1,
                    backgroundColor: alpha(theme.palette.background.default, 1),
                    overflow: "auto",
                }); } }, { children: _jsx(Stack, __assign({ spacing: 2, sx: {
                        alignItems: "center",
                        mx: 3,
                        pb: 5,
                        mt: { xs: 8, md: 0 },
                    } }, { children: _jsx(MainGrid, { club: club }) })) }))] }))
    // <div>
    //   Admin Portal
    //   <button onClick={handleLogout}>Logout</button>
    // </div>
    );
};
export default Admin;
