import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { MatchState } from "shared/models/matchModels";
import AfricanoMatchLobby from "./africanomatchlobby";
import AfricanoInfo from "./africanoinfo";
import AfricanoPostMatch from "./africanopostmatch";
var MatchAfricano = function (_a) {
    var matchData = _a.matchData;
    switch (matchData.state) {
        case MatchState.NEW:
            return _jsx(AfricanoMatchLobby, { data: matchData });
        case MatchState.READY:
        case MatchState.IN_PROGRESS:
            return _jsx(AfricanoInfo, { data: matchData });
        case MatchState.FINISHED:
            return _jsx(AfricanoPostMatch, { data: matchData });
    }
};
export default MatchAfricano;
