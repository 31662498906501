var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LogoutIcon from "@mui/icons-material/Logout";
var mainListItems = [{ text: "Club Management", icon: _jsx(HomeRoundedIcon, {}) }];
var secondaryListItems = [{ text: "Logout", icon: _jsx(LogoutIcon, {}) }];
export default function MenuContent() {
    return (_jsxs(Stack, __assign({ sx: { flexGrow: 1, p: 1, justifyContent: "space-between" } }, { children: [_jsx(List, __assign({ dense: true }, { children: mainListItems.map(function (item, index) { return (_jsx(ListItem, __assign({ disablePadding: true, sx: { display: "block" } }, { children: _jsxs(ListItemButton, __assign({ selected: index === 0 }, { children: [_jsx(ListItemIcon, { children: item.icon }), _jsx(ListItemText, { primary: item.text })] })) }), index)); }) })), _jsx(List, __assign({ dense: true }, { children: secondaryListItems.map(function (item, index) { return (_jsx(ListItem, __assign({ disablePadding: true, sx: { display: "block" } }, { children: _jsxs(ListItemButton, { children: [_jsx(ListItemIcon, { children: item.icon }), _jsx(ListItemText, { primary: item.text })] }) }), index)); }) }))] })));
}
