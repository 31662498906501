var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ClubSelector from "./ClubSelector";
import MenuContent from "./MenuContent";
var drawerWidth = 240;
var Drawer = styled(MuiDrawer)((_a = {
        width: drawerWidth,
        flexShrink: 0,
        boxSizing: "border-box",
        mt: 10
    },
    _a["& .".concat(drawerClasses.paper)] = {
        width: drawerWidth,
        boxSizing: "border-box",
    },
    _a));
export default function SideMenu(_a) {
    var _b;
    var user = _a.user, clubs = _a.clubs;
    return (_jsxs(Drawer, __assign({ variant: "permanent", sx: (_b = {
                display: { xs: "none", md: "block" }
            },
            _b["& .".concat(drawerClasses.paper)] = {
                backgroundColor: "background.paper",
            },
            _b) }, { children: [_jsx(Box, __assign({ sx: {
                    display: "flex",
                    mt: "calc(var(--template-frame-height, 0px) + 4px)",
                    p: 1.5,
                } }, { children: _jsx(ClubSelector, { clubs: clubs }) })), _jsx(Divider, {}), _jsx(Box, __assign({ sx: {
                    overflow: "auto",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                } }, { children: _jsx(MenuContent, {}) })), _jsxs(Stack, __assign({ direction: "row", sx: {
                    p: 2,
                    gap: 1,
                    alignItems: "center",
                    borderTop: "1px solid",
                    borderColor: "divider",
                } }, { children: [_jsx(Avatar, { sizes: "small", alt: user.displayName, src: user.profilePictureUrl, sx: { width: 36, height: 36 } }), _jsxs(Box, __assign({ sx: { mr: "auto" } }, { children: [_jsx(Typography, __assign({ variant: "body2", sx: { fontWeight: 500, lineHeight: "16px" } }, { children: user.displayName })), _jsx(Typography, __assign({ variant: "caption", sx: { color: "text.secondary" } }, { children: "user email goes here" }))] }))] }))] })));
}
