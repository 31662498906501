var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box, Typography, Card, CircularProgress, Avatar, } from "@mui/material";
import { fetchClubUsers, removeUserFromClub } from "@/dao/club";
import { UserClubRole } from "shared/models/clubModels";
import AddUserDialog from "./AddUserDialog"; // Import the new component
import AddUserIcon from "@mui/icons-material/Add";
var ClubUserTable = function (_a) {
    var clubUid = _a.clubUid;
    var _b = useState([]), users = _b[0], setUsers = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), isAddDialogOpen = _d[0], setIsAddDialogOpen = _d[1];
    useEffect(function () {
        var loadUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
            var clubUsers, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        setLoading(true);
                        return [4 /*yield*/, fetchClubUsers(clubUid)];
                    case 1:
                        clubUsers = _a.sent();
                        console.log("Club users:", clubUsers);
                        setUsers(clubUsers);
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        console.error("Error fetching club users:", error_1);
                        return [3 /*break*/, 4];
                    case 3:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        loadUsers();
    }, [clubUid]);
    var handleDelete = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, removeUserFromClub(clubUid, id)];
                case 1:
                    _a.sent();
                    setUsers(function (prevUsers) { return prevUsers.filter(function (user) { return user.user_id !== id; }); });
                    return [3 /*break*/, 4];
                case 2:
                    error_2 = _a.sent();
                    console.error("Error removing user from club:", error_2);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleUserAdded = function () { return __awaiter(void 0, void 0, void 0, function () {
        var clubUsers, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, fetchClubUsers(clubUid)];
                case 1:
                    clubUsers = _a.sent();
                    setUsers(clubUsers);
                    return [3 /*break*/, 4];
                case 2:
                    error_3 = _a.sent();
                    console.error("Error adding user to club:", error_3);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var usersTableItems = users.map(function (user) { return ({
        id: user.user_id,
        displayName: user.user.displayName,
        role: user.role || UserClubRole.ADMIN,
        joinedAt: user.joined_at
            ? user.joined_at.toISOString().substring(0, 10)
            : "Unknown",
        photoUrl: user.user.profilePictureUrl || "",
    }); });
    var columns = [
        {
            field: "displayName",
            headerName: "Display Name",
            flex: 1.5,
            renderCell: function (params) { return (_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center", gap: 1 } }, { children: [_jsx(Avatar, __assign({ src: params.row.photoUrl, sx: { width: 30, height: 30 } }, { children: params.row.displayName.charAt(0) })), params.row.displayName] }))); },
        },
        {
            field: "role",
            headerName: "Role",
            flex: 1,
        },
        {
            field: "joinedAt",
            headerName: "Joined At",
            flex: 1,
        },
        {
            field: "action",
            headerName: "Action",
            renderCell: function (params) { return (_jsx(Button, __assign({ color: "error", onClick: function () { return handleDelete(params.row.id); }, sx: { height: 30 }, disabled: loading }, { children: "Delete" }))); },
            sortable: false,
            flex: 1,
        },
    ];
    return (_jsxs(Card, __assign({ sx: { padding: 2, position: "relative", minHeight: 300 } }, { children: [_jsxs(Box, __assign({ sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0,
                } }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: "Staff" })), _jsx(Button, __assign({ sx: { height: 25 }, onClick: function () { return setIsAddDialogOpen(true); } }, { children: _jsx(AddUserIcon, {}) }))] })), _jsx(Box, __assign({ sx: {
                    height: 300,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                } }, { children: loading ? (_jsx(CircularProgress, {})) : (_jsx(DataGrid, { rows: usersTableItems, columns: columns, rowHeight: 50 })) })), _jsx(AddUserDialog, { open: isAddDialogOpen, onClose: function () { return setIsAddDialogOpen(false); }, clubUid: clubUid, onUserAdded: handleUserAdded })] })));
};
export default ClubUserTable;
