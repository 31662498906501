var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import MuiAvatar from "@mui/material/Avatar";
import MuiListItemAvatar from "@mui/material/ListItemAvatar";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Select, { selectClasses } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
var Avatar = styled(MuiAvatar)(function (_a) {
    var theme = _a.theme;
    return ({
        width: 28,
        height: 28,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        border: "1px solid ".concat(theme.palette.divider),
    });
});
var ListItemAvatar = styled(MuiListItemAvatar)({
    minWidth: 0,
    marginRight: 12,
});
export default function ClubSelector(_a) {
    var _b;
    var clubs = _a.clubs;
    var _c = React.useState(clubs[0].uid), club = _c[0], setClub = _c[1];
    var handleChange = function (event) {
        setClub(event.target.value);
    };
    return (_jsxs(Select, __assign({ labelId: "company-select", id: "company-simple-select", value: club, onChange: handleChange, displayEmpty: true, inputProps: { "aria-label": "Select Club" }, fullWidth: true, sx: (_b = {
                maxHeight: 56,
                width: 215,
                "&.MuiList-root": {
                    p: "8px",
                }
            },
            _b["& .".concat(selectClasses.select)] = {
                display: "flex",
                alignItems: "center",
                gap: "2px",
                pl: 1,
            },
            _b) }, { children: [_jsx(ListSubheader, __assign({ sx: { pt: 0 } }, { children: "Clubs" })), clubs.map(function (club) { return (_jsxs(MenuItem, __assign({ value: club.uid }, { children: [_jsx(ListItemAvatar, { children: _jsx(Avatar, __assign({ alt: club.name }, { children: _jsx(SportsTennisIcon, { sx: { fontSize: "1rem" } }) })) }), _jsx(ListItemText, { primary: club.name, secondary: club.description })] }), club.uid)); })] })));
}
