import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ReactComponent as MspLogo } from "../msp-logo.svg";
import { ReactComponent as MspLogoWhite } from "../msp-logo-white.svg";
var Logo = function (_a) {
    var width = _a.width, height = _a.height, paddingTop = _a.paddingTop, useWhiteLogo = _a.useWhiteLogo;
    // let pTop = paddingTop ?? "0";
    if (useWhiteLogo) {
        return _jsx(MspLogoWhite, { width: width, height: height });
    }
    return _jsx(MspLogo, { width: width, height: height });
};
export default Logo;
