var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Card } from "@mui/material";
var GameTable = function () {
    var games = [
        {
            id: 1,
            gameName: "Court 1",
            players: "8/16",
            limit: "8 minutes",
            gameType: "Singles",
            format: "Timed",
            state: "READY",
            created_at: "2025-02-01",
            created_by: "Jane Smith",
        },
        {
            id: 2,
            gameName: "Court 2",
            players: "8/8",
            limit: "12 minutes",
            gameType: "Singles",
            format: "Timed",
            state: "In Progress",
            created_at: "2025-01-01",
            created_by: "Jane Smith",
        },
        {
            id: 3,
            gameName: "Court 3",
            players: "8/8",
            limit: "16 points",
            gameType: "Singles",
            format: "Points",
            state: "Finished",
            created_at: "2024-12-01",
            created_by: "John Smith",
        },
        {
            id: 4,
            gameName: "Court 4",
            players: "16/16",
            limit: "8 points",
            gameType: "Teamed",
            format: "Points",
            state: "Finished",
            created_at: "2024-11-01",
            created_by: "Jane Smith",
        },
    ];
    var columns = [
        { field: "id", headerName: "ID", flex: 1 },
        { field: "gameName", headerName: "Name", flex: 1 },
        { field: "players", headerName: "Players", flex: 1 },
        { field: "format", headerName: "Format", flex: 1 },
        { field: "limit", headerName: "limit", flex: 1 },
        { field: "gameType", headerName: "type", flex: 1 },
        { field: "state", headerName: "State", flex: 1 },
        { field: "created_at", headerName: "Created At", flex: 1 },
        { field: "created_by", headerName: "Created By", flex: 1 },
    ];
    return (_jsxs(Card, __assign({ sx: { padding: 2 } }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: "Courts" })), _jsx(Box, __assign({ sx: { height: 300, width: "100%" } }, { children: _jsx(DataGrid, { rows: games, columns: columns, rowHeight: 30 }) }))] })));
};
export default GameTable;
