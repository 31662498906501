var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import Logo from "@/components/Logo";
import { MatchFormat, MatchState, } from "shared/models/matchModels";
import { calculatePlayerScores } from "shared/utils/logic";
import { ReactComponent as MspLogoWhite } from "../../../msp-logo-white.svg";
import ChevronSvg from "../../../chevrons.svg"; // Ensure you have the SVG imported correctly
import useCountdown from "@/hooks/useCountdown";
import Timer from "@/components/Timer";
import logo from "../../../msp-logo.png";
import { basicColors } from "@/utils/colors";
var TeamInfo = function (_a) {
    var data = _a.data;
    var playersScore = calculatePlayerScores(data.rounds);
    var previosRound = data.round > 0 ? data.rounds[data.round - 1] : undefined;
    var currentRound = data.rounds[data.round];
    var nextRound = data.round < data.rounds.length ? data.rounds[data.round + 1] : undefined;
    var _b = useCountdown(data.limit, data.format === MatchFormat.TIMED &&
        data.state === MatchState.IN_PROGRESS), initialCountdown = _b.initialCountdown, showTimer = _b.showTimer, countdown = _b.countdown, timerFinished = _b.timerFinished, formatTime = _b.formatTime;
    return showTimer ? (_jsx(Grid, __assign({ item: true, xs: 12, sx: { height: "100vh" }, bgcolor: "#8BC53F" }, { children: _jsxs(Box, __assign({ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }, { children: [_jsx(Timer, { initialCountdown: initialCountdown, countdown: countdown, formatTime: formatTime, roundNumber: data.round + 1, small: false }), _jsx(Box, __assign({ sx: {
                        position: "absolute",
                        bottom: "2vh",
                        left: "3vh",
                    } }, { children: _jsx(MspLogoWhite, { width: "15vw" }) }))] })) }))) : timerFinished ? (_jsx(Grid, __assign({ item: true, xs: 12, sx: { height: "100vh" }, bgcolor: "#8BC53F" }, { children: _jsx(Box, __assign({ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }, { children: _jsx(Typography, __assign({ variant: "h1", align: "center", sx: {
                    fontWeight: "bold",
                    color: "#ffffff",
                    fontFamily: "korolev",
                    fontSize: "30vh",
                } }, { children: "Please submit score" })) })) }))) : (_jsxs(Box, __assign({ sx: {
            height: "100vh",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "background.default",
            color: "text.primary",
        } }, { children: [_jsxs(Box, __assign({ sx: {
                    flex: 1,
                    backgroundColor: "#8BC53F",
                    color: "common.white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "2vh",
                } }, { children: [_jsx(Box, __assign({ sx: { textAlign: "center", marginBottom: "0vh" } }, { children: _jsx("img", { src: logo, alt: "MSP Logo", style: { width: "8vw", height: "auto", paddingTop: "2vh" } }) })), _jsx(Typography, __assign({ variant: "h5", sx: {
                            fontWeight: "bold",
                            textAlign: "center",
                            fontFamily: "bc-alphapipe",
                            fontSize: "2.6vw",
                        } }, { children: "Leaderboard" })), _jsx(Box, __assign({ sx: { width: "100%", marginTop: "1vh" } }, { children: playersScore.map(function (player, index) {
                            if (index % 2 === 0)
                                return null; // Skip even indices, handle them in the odd index case
                            return (_jsxs(Box, __assign({ sx: {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0.6vh 0",
                                    borderBottom: "0.2vh solid rgba(255, 255, 255, 0.3)",
                                    position: "relative",
                                } }, { children: [_jsx(Box, { sx: {
                                            width: "4%",
                                            height: "8vh",
                                            backgroundColor: basicColors[(player.playerIndex - 1) / 2],
                                            marginRight: "1vw",
                                        } }), _jsxs(Box, __assign({ sx: { flex: 1, textAlign: "left" } }, { children: [_jsx(Typography, __assign({ variant: "body1", sx: { fontSize: "1.5vw", fontFamily: "korolev" } }, { children: data.playerList[player.playerIndex - 1].name })), _jsx(Typography, __assign({ variant: "body1", sx: { fontSize: "1.5vw", fontFamily: "korolev" } }, { children: data.playerList[player.playerIndex].name }))] })), _jsx(Typography, __assign({ variant: "body1", sx: {
                                            fontWeight: "bold",
                                            fontSize: "3vw",
                                            fontFamily: "korolev",
                                        } }, { children: player.playerScore }))] }), index));
                        }) }))] })), _jsxs(Box, __assign({ sx: {
                    flex: 4,
                    padding: "3vh",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#f0f4ea",
                    position: "relative",
                } }, { children: [_jsx(Box, __assign({ sx: {
                            position: "absolute",
                            top: "2vh",
                            right: "3vh",
                            backgroundColor: "#8BC53F",
                            color: "white",
                            padding: "0vh 4vh",
                            borderRadius: "15px",
                        } }, { children: _jsxs(Typography, __assign({ variant: "h6", sx: {
                                fontWeight: "bold",
                                fontFamily: "korolev",
                                fontStyle: "italic",
                                fontSize: "2vw",
                            } }, { children: ["Round ", data.round + 1, "/", data.rounds.length] })) })), _jsx(Box, __assign({ sx: { textAlign: "center", marginTop: "4vh" } }, { children: _jsx(Logo, { width: "16vw", height: "auto" }) })), _jsx(Box, __assign({ sx: { textAlign: "center" } }, { children: _jsx(Typography, __assign({ variant: "h4", sx: { fontFamily: "korolev", fontSize: "7.5vh" } }, { children: data.sessionName })) })), _jsxs(Grid, __assign({ container: true, spacing: 0 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 4 }, { children: [_jsx(Typography, __assign({ variant: "h6", sx: {
                                            textAlign: "center",
                                            marginBottom: "1vh",
                                            fontFamily: "korolev",
                                            fontSize: "1.5vw",
                                        } }, { children: "PREVIOUS ROUND" })), previosRound &&
                                        previosRound.games.map(function (game, index) { return (_jsxs(Paper, __assign({ elevation: 0, sx: {
                                                paddingX: "0.5vh",
                                                marginBottom: "2vh",
                                                position: "relative",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                paddingRight: "5vw",
                                                borderRadius: "0vw",
                                            } }, { children: [_jsxs(Box, __assign({ sx: { flex: 1, textAlign: "center" } }, { children: [_jsxs(Typography, __assign({ variant: "h6", sx: {
                                                                color: "grey",
                                                                textAlign: "center",
                                                                fontFamily: "korolev",
                                                                fontSize: "1.8vw",
                                                            } }, { children: ["COURT ", index + 1] })), _jsxs(Box, __assign({ sx: {
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                paddingX: "0.4vh",
                                                            } }, { children: [_jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                                                                                fontFamily: "korolev",
                                                                                fontSize: "1vw",
                                                                                textAlign: "left",
                                                                                color: "grey",
                                                                            } }, { children: data.playerList[game.team1[0]].name })), _jsx(Typography, __assign({ variant: "body2", sx: {
                                                                                fontFamily: "korolev",
                                                                                fontSize: "1vw",
                                                                                textAlign: "left",
                                                                                color: "grey",
                                                                            } }, { children: data.playerList[game.team1[1]].name }))] }), _jsxs(Typography, __assign({ variant: "h6", sx: { fontFamily: "korolev", fontSize: "2vw" } }, { children: [game.team1Score, ":", game.team2Score] })), _jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                                                                                fontFamily: "korolev",
                                                                                fontSize: "1vw",
                                                                                textAlign: "right",
                                                                                color: "grey",
                                                                            } }, { children: data.playerList[game.team2[0]].name })), _jsx(Typography, __assign({ variant: "body2", sx: {
                                                                                fontFamily: "korolev",
                                                                                fontSize: "1vw",
                                                                                textAlign: "right",
                                                                                color: "grey",
                                                                            } }, { children: data.playerList[game.team2[1]].name }))] })] }))] })), _jsx("img", { src: ChevronSvg, alt: "Chevron", style: {
                                                        position: "absolute",
                                                        height: "50%",
                                                        right: "1vw",
                                                        top: "25%",
                                                    } })] }), index)); })] })), _jsxs(Grid, __assign({ item: true, xs: 4, sx: { zIndex: 2 } }, { children: [_jsx(Typography, __assign({ variant: "h6", sx: {
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            marginBottom: "1vh",
                                            fontFamily: "korolev",
                                            fontSize: "1.5vw",
                                        } }, { children: "CURRENT ROUND" })), currentRound.games.map(function (game, index) { return (_jsx(Paper, __assign({ elevation: 0, sx: {
                                            paddingX: "1vh",
                                            marginBottom: "2vh",
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            backgroundColor: "#8BC53F",
                                            color: "white",
                                            borderRadius: "0.8vw",
                                            transform: "scale(1.04)",
                                            zIndex: 2,
                                        } }, { children: _jsxs(Box, __assign({ sx: { flex: 1, textAlign: "center" } }, { children: [_jsxs(Typography, __assign({ variant: "h6", sx: {
                                                        fontWeight: "bold",
                                                        textAlign: "center",
                                                        fontFamily: "korolev",
                                                        fontSize: "1.8vw",
                                                    } }, { children: ["COURT ", index + 1] })), _jsxs(Box, __assign({ sx: {
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        paddingX: "0.4vh",
                                                    } }, { children: [_jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                                                                        fontFamily: "korolev",
                                                                        fontSize: "1vw",
                                                                        textAlign: "left",
                                                                        fontWeight: "bold",
                                                                    } }, { children: data.playerList[game.team1[0]].name })), _jsx(Typography, __assign({ variant: "body2", sx: {
                                                                        fontFamily: "korolev",
                                                                        fontSize: "1vw",
                                                                        textAlign: "left",
                                                                        fontWeight: "bold",
                                                                    } }, { children: data.playerList[game.team1[1]].name }))] }), _jsx(Typography, __assign({ variant: "h6", sx: {
                                                                fontWeight: "bold",
                                                                fontFamily: "korolev",
                                                                fontSize: "2vw",
                                                            } }, { children: "VS" })), _jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                                                                        fontFamily: "korolev",
                                                                        fontSize: "1vw",
                                                                        textAlign: "right",
                                                                        fontWeight: "bold",
                                                                    } }, { children: data.playerList[game.team2[0]].name })), _jsx(Typography, __assign({ variant: "body2", sx: {
                                                                        fontFamily: "korolev",
                                                                        fontSize: "1vw",
                                                                        textAlign: "right",
                                                                        fontWeight: "bold",
                                                                    } }, { children: data.playerList[game.team2[1]].name }))] })] }))] })) }), index)); })] })), _jsxs(Grid, __assign({ item: true, xs: 4 }, { children: [_jsx(Typography, __assign({ variant: "h6", sx: {
                                            textAlign: "center",
                                            marginBottom: "1vh",
                                            fontFamily: "korolev",
                                            fontSize: "1.5vw",
                                        } }, { children: "NEXT ROUND" })), nextRound &&
                                        nextRound.games.map(function (game, index) { return (_jsxs(Paper, __assign({ elevation: 0, sx: {
                                                paddingX: "0.5vh",
                                                marginBottom: "2vh",
                                                position: "relative",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                paddingLeft: "5vw",
                                                borderRadius: "0vw",
                                            } }, { children: [_jsx("img", { src: ChevronSvg, alt: "Chevron", style: {
                                                        position: "absolute",
                                                        height: "50%",
                                                        left: "1vw",
                                                        top: "25%",
                                                    } }), _jsxs(Box, __assign({ sx: { flex: 1, textAlign: "center" } }, { children: [_jsxs(Typography, __assign({ variant: "h6", sx: {
                                                                color: "grey",
                                                                textAlign: "center",
                                                                fontFamily: "korolev",
                                                                fontSize: "1.8vw",
                                                            } }, { children: ["COURT ", index + 1] })), _jsxs(Box, __assign({ sx: {
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                paddingX: "0.4vh",
                                                            } }, { children: [_jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                                                                                fontFamily: "korolev",
                                                                                fontSize: "1vw",
                                                                                textAlign: "left",
                                                                                color: "grey",
                                                                            } }, { children: data.playerList[game.team1[0]].name })), _jsx(Typography, __assign({ variant: "body2", sx: {
                                                                                fontFamily: "korolev",
                                                                                fontSize: "1vw",
                                                                                textAlign: "left",
                                                                                color: "grey",
                                                                            } }, { children: data.playerList[game.team1[1]].name }))] }), _jsx(Typography, __assign({ variant: "h6", sx: {
                                                                        fontFamily: "korolev",
                                                                        fontSize: "2vw",
                                                                        color: "grey",
                                                                    } }, { children: "VS" })), _jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                                                                                fontFamily: "korolev",
                                                                                fontSize: "1vw",
                                                                                textAlign: "right",
                                                                                color: "grey",
                                                                            } }, { children: data.playerList[game.team2[0]].name })), _jsx(Typography, __assign({ variant: "body2", sx: {
                                                                                fontFamily: "korolev",
                                                                                fontSize: "1vw",
                                                                                textAlign: "right",
                                                                                color: "grey",
                                                                            } }, { children: data.playerList[game.team2[1]].name }))] })] }))] }))] }), index)); })] }))] }))] }))] })));
};
export default TeamInfo;
