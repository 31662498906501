var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography, Box } from "@mui/material";
var Timer = function (_a) {
    var initialCountdown = _a.initialCountdown, countdown = _a.countdown, roundNumber = _a.roundNumber, formatTime = _a.formatTime, _b = _a.small, small = _b === void 0 ? false : _b;
    return small ? (_jsx(Box, __assign({ justifyContent: "center", alignItems: "center" }, { children: _jsx(Typography, __assign({ sx: {
                fontSize: "9vh",
                color: "#ffffff",
                fontFamily: "korolev",
                fontWeight: "bold",
            }, variant: "h1", align: "center" }, { children: initialCountdown > 0 ? initialCountdown : formatTime(countdown) })) }))) : (_jsxs(Box, __assign({ justifyContent: "center", alignItems: "center" }, { children: [_jsxs(Typography, __assign({ sx: {
                    fontSize: "10vh",
                    color: "#ffffff",
                    fontFamily: "korolev",
                    fontStyle: "italic",
                    fontWeight: "bold",
                }, variant: "h1", align: "center" }, { children: ["ROUND ", roundNumber] })), _jsx(Typography, __assign({ sx: {
                    fontSize: "50vh",
                    color: "#ffffff",
                    fontFamily: "korolev",
                    fontWeight: "bold",
                }, variant: "h1", align: "center" }, { children: initialCountdown > 0 ? initialCountdown : formatTime(countdown) }))] })));
};
export default Timer;
