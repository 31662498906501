var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { DataGrid, useGridApiRef, } from "@mui/x-data-grid";
import { Box, Typography, Card, Button, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, } from "@mui/material";
import { Save as SaveIcon, Add as AddIcon, Edit as EditIcon, } from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
var CourtTable = function (_a) {
    var courts = _a.courts, onCourtsChange = _a.onCourtsChange;
    var _b = useState(courts.map(function (court, index) { return ({ id: index + 1, courtName: court }); })), courtList = _b[0], setCourtList = _b[1];
    var _c = useState(false), isSaveDialogOpen = _c[0], setIsSaveDialogOpen = _c[1];
    var _d = useState(false), showSnackbar = _d[0], setShowSnackbar = _d[1];
    var apiRef = useGridApiRef(); // Reference to DataGrid API
    // Adds a new row and enters edit mode immediately
    var handleAddCourt = function () {
        var newId = courtList.length + 1;
        var newCourt = { id: newId, courtName: "", isEdited: true };
        setCourtList(__spreadArray(__spreadArray([], courtList, true), [newCourt], false));
        // Focus on new row's "courtName" field after a short delay
        setTimeout(function () {
            apiRef.current.startCellEditMode({ id: newId, field: "courtName" });
        }, 100);
    };
    // Handle user editing a court name
    var handleEditCourt = function (params) {
        setCourtList(function (prevList) {
            return prevList.map(function (court) {
                return court.id === params.id
                    ? __assign(__assign({}, court), { courtName: params.value, isEdited: true }) : court;
            });
        });
    };
    // Handle saving changes
    var handleSaveCourts = function () {
        setIsSaveDialogOpen(false);
        onCourtsChange(courtList.map(function (court) { return court.courtName; }));
        setCourtList(function (prevList) {
            return prevList.map(function (court) { return (__assign(__assign({}, court), { isEdited: false })); });
        }); // Reset edit indicators
        setShowSnackbar(true);
    };
    var columns = [
        { field: "id", headerName: "ID", flex: 0.3 },
        {
            field: "courtName",
            headerName: "Court Name",
            flex: 1,
            editable: true,
            cellClassName: function (params) { return (params.row.isEdited ? "edited-cell" : ""); },
            renderCell: function (params) { return (_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center", width: "100%" } }, { children: [params.row.isEdited && (_jsx(EditIcon, { fontSize: "small", color: "primary", sx: { marginRight: 1 } })), params.value || (_jsx(Typography, __assign({ variant: "caption", color: "textSecondary" }, { children: "Click to edit" })))] }))); },
        },
    ];
    return (_jsxs(Card, __assign({ sx: { padding: 2 } }, { children: [_jsxs(Box, __assign({ sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 2,
                } }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: "Courts" })), _jsxs(Box, { children: [_jsx(Button, __assign({ variant: "contained", color: "primary", onClick: handleAddCourt, sx: { marginRight: 1 }, startIcon: _jsx(AddIcon, {}) }, { children: "Add Court" })), _jsx(Button, __assign({ variant: "contained", color: "success", onClick: function () { return setIsSaveDialogOpen(true); }, startIcon: _jsx(SaveIcon, {}) }, { children: "Save" }))] })] })), _jsx(Box, __assign({ sx: { height: 300, width: "100%" } }, { children: _jsx(DataGrid, { apiRef: apiRef, rows: courtList, columns: columns, rowHeight: 50, autoPageSize: true, editMode: "row", onCellEditStop: handleEditCourt }) })), _jsxs(Dialog, __assign({ open: isSaveDialogOpen, onClose: function () { return setIsSaveDialogOpen(false); } }, { children: [_jsx(DialogTitle, { children: "Confirm Save" }), _jsx(DialogContent, { children: "Are you sure you want to save the updated court data?" }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () { return setIsSaveDialogOpen(false); }, color: "error" }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: handleSaveCourts, color: "primary" }, { children: "Save" }))] })] })), _jsx(Snackbar, __assign({ open: showSnackbar, autoHideDuration: 3000, onClose: function () { return setShowSnackbar(false); } }, { children: _jsx(MuiAlert, __assign({ onClose: function () { return setShowSnackbar(false); }, severity: "success", sx: { width: "100%" } }, { children: "Courts saved successfully!" })) }))] })));
};
export default CourtTable;
