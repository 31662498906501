var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import Box from "@mui/material/Box";
import ClubUserTable from "./ClubUserTable";
import CourtTable from "./CourtTable";
import { Grid } from "@mui/material";
import GameTable from "./GameTable";
var updateCourtsInFirestore = function (updatedCourts) {
    // Update courts in Firestore
    console.log(updatedCourts);
};
export default function MainGrid(_a) {
    var club = _a.club;
    return (_jsx(Box, __assign({ sx: {
            width: "100%",
            maxWidth: { sm: "100%", md: "1700px" },
            height: "100vh",
            padding: 2,
        } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(ClubUserTable, { clubUid: club.uid }) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsx(CourtTable, { courts: club.courts, onCourtsChange: updateCourtsInFirestore }) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 12 }, { children: _jsx(GameTable, {}) }))] })) })));
}
